<template>
  <v-snackbar v-model="show" :timeout="timeout" top right>
    {{ text }}
    <template v-slot:action="{ attrs }">
      <v-btn text color="pink" v-bind="attrs" @click="show = false">
        Закрыть
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "SnackBar",
  data: () => ({
    show: false,
    color: "",
    text: "",
    timeout: 5000,
  }),
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "ui/SHOW_MESSAGE") {
        console.log('showSnack')

        this.text = state.ui.text;
        // this.timeout = state.snackbar.timeout;
        this.show = true;
      }
    });
  },
}
</script>

<style scoped>

</style>