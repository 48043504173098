import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default {
    namespaced: true,
    state: {},
    mutations: {
        add_exceptions_success(state) {
            console.log("add_exceptions_success")
        },
        add_exceptions_error(state) {
            console.log("add_exceptions_error")
        },
        get_exceptions_success(state) {
            console.log("get_exceptions_success")
        },
        get_exceptions_error(state) {
            console.log("get_exceptions_error")
        },
        delete_exception_success(state) {
            console.log("delete_exception_success")
        },
        delete_exception_error(state) {
            console.log("delete_exception_error")
        },
        edit_exception_success(state) {
            console.log("edit_exception_success")
        },
        edit_exception_error(state) {
            console.log("edit_exception_error")
        },
    },
    actions: {
        addExceptions({commit, state, getters, rootGetters}, exceptions) {
            return new Promise((resolve, reject) => {
                axios({
                    url: `${process.env.VUE_APP_API_URL}/api-exception/add-exceptions`,
                    method: 'POST',
                    data: exceptions
                })
                    .then(resp => {
                        let data = resp.data;
                        commit('add_exceptions_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('add_exceptions_error')
                        reject(err.response.data)
                    })
            })
        },
        editException({commit, state, getters, rootGetters}, exception) {
            return new Promise((resolve, reject) => {
                axios({
                    url: `${process.env.VUE_APP_API_URL}/api-exception/edit-exception`,
                    method: 'POST',
                    data: exception
                })
                    .then(resp => {
                        let data = resp.data;
                        commit('edit_exception_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('edit_exception_error')
                        reject(err.response.data)
                    })
            })
        },
        deleteException({commit, state, getters, rootGetters}, exception) {
            return new Promise((resolve, reject) => {
                axios({
                    url: `${process.env.VUE_APP_API_URL}/api-exception/delete-exception`,
                    method: 'POST',
                    data: exception
                })
                    .then(resp => {
                        let data = resp.data;
                        commit('delete_exception_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('delete_exception_error')
                        reject(err.response.data)
                    })
            })
        },
        deleteExceptionAll({commit, state, getters, rootGetters}, exception) {
            return new Promise((resolve, reject) => {
                axios({
                    url: `${process.env.VUE_APP_API_URL}/api-exception/delete-exception?all=true`,
                    method: 'POST',
                    data: exception
                })
                    .then(resp => {
                        let data = resp.data;
                        commit('delete_exception_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('delete_exception_error')
                        reject(err.response.data)
                    })
            })
        },
        getExceptions({commit, state, getters, rootGetters}) {
            return new Promise((resolve, reject) => {
                axios({url: `${process.env.VUE_APP_API_URL}/api-exception/get-exceptions`, method: 'GET'})
                    .then(resp => {
                        let data = resp.data;
                        commit('get_exceptions_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('get_exceptions_error')
                        reject(err.response.data)
                    })
            })
        },
    },
    getters: {}
}
