import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default {
    namespaced: true,
    state: {},
    mutations: {
        add_mails_success(state) {
            console.log("add_mails_success")
        },
        add_mails_error(state) {
            console.log("add_mails_error")
        },
        delete_mail_success(state) {
            console.log("delete_mail_success")
        },
        delete_mail_error(state) {
            console.log("delete_mail_error")
        },
        edit_mail_success(state) {
            console.log("edit_mail_success")
        },
        edit_mail_error(state) {
            console.log("edit_mail_error")
        },
        get_mails_success(state) {
            console.log("get_mails_success")
        },
        get_mails_error(state) {
            console.log("get_mails_error")
        },
        test_mail_success(state) {
            console.log("test_mail_success")
        },
        test_mail_error(state) {
            console.log("test_mail_error")
        },
        assign_proxies_success(state) {
            console.log("assign_proxies_success")
        },
        assign_proxies_error(state) {
            console.log("assign_proxies_error")
        },
        set_flagged_success() {
            console.log('set_flagged_success')
        },
        set_flagged_error() {
            console.log('set_flagged_error')
        },
        change_proxies_success() {
            console.log('change_proxies_success')
        },
        change_proxies_error() {
            console.log('change_proxies_error')
        },
        set_pause_success() {
            console.log('set_pause_success')
        },
        set_pause_error() {
            console.log('set_pause_error')
        },
        play_mail_success() {
            console.log('play_mail_success')
        },
        play_mail_error() {
            console.log('play_mail_error')
        },
        stop_mail_success() {
            console.log('stop_mail_success')
        },
        stop_mail_error() {
            console.log('stop_mail_error')
        },
        restart_core_success() {
            console.log('restart_core_success')
        },
        restart_core_error() {
            console.log('restart_core_error')
        },
        get_mails_all_success() {
            console.log('get_mails_all_success')
        },
        get_mails_all_error() {
            console.log('get_mails_all_error')
        },
    },
    actions: {
        addMails({commit, state, getters, rootGetters}, mails) {
            return new Promise((resolve, reject) => {
                axios({url: `${process.env.VUE_APP_API_URL}/api-mail/add-mails?test=true`, method: 'POST', data: mails})
                    .then(resp => {
                        let data = resp.data;
                        commit('add_mails_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('add_mails_error')
                        reject(err.response.data)
                    })
            })
        },
        addMailsUpload({commit, state, getters, rootGetters}, mails) {
            return new Promise((resolve, reject) => {
                axios({
                    url: `${process.env.VUE_APP_API_URL}/api-mail/add-mails?test=true`,
                    method: 'POST',
                    data: mails
                })
                    .then(resp => {
                        let data = resp.data;
                        commit('add_mails_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('add_mails_error')
                        reject(err.response.data)
                    })
            })
        },
        restartCore({commit, state, getters, rootGetters}, mails) {
            return new Promise((resolve, reject) => {
                axios({url: `${process.env.VUE_APP_API_URL}/api-mail/restart-core?test=true`, method: 'POST'})
                    .then(resp => {
                        let data = resp.data;
                        commit('restart_core_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('restart_core_error')
                        reject(err.response.data)
                    })
            })
        },
        editMail({commit, state, getters, rootGetters}, mail) {
            return new Promise((resolve, reject) => {
                axios({url: `${process.env.VUE_APP_API_URL}/api-mail/edit-mail`, method: 'POST', data: mail})
                    .then(resp => {
                        let data = resp.data;
                        commit('edit_mail_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('edit_mail_error')
                        reject(err.response.data)
                    })
            })
        },
        deleteMail({commit, state, getters, rootGetters}, mail) {
            return new Promise((resolve, reject) => {
                axios({url: `${process.env.VUE_APP_API_URL}/api-mail/delete-mail`, method: 'POST', data: mail})
                    .then(resp => {
                        let data = resp.data;
                        commit('delete_mail_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('delete_mail_error')
                        reject(err.response.data)
                    })
            })
        },

        deleteMailsAll({commit, state, getters, rootGetters}, mail) {
            return new Promise((resolve, reject) => {
                axios({url: `${process.env.VUE_APP_API_URL}/api-mail/delete-mail?all=true`, method: 'POST', data: mail})
                    .then(resp => {
                        let data = resp.data;
                        commit('delete_mail_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('delete_mail_error')
                        reject(err.response.data)
                    })
            })
        },

        playMailsAll({commit, state, getters, rootGetters}, obj) {
            return new Promise((resolve, reject) => {
                axios({url: `${process.env.VUE_APP_API_URL}/api-mail/play-mails?all=` + obj.all, method: 'POST', data: obj.array})
                    .then(resp => {
                        let data = resp.data;
                        commit('play_mail_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('play_mail_error')
                        reject(err.response.data)
                    })
            })
        },
        stopMailsAll({commit, state, getters, rootGetters}, obj) {
            return new Promise((resolve, reject) => {
                axios({url: `${process.env.VUE_APP_API_URL}/api-mail/stop-mails?all=` + obj.all, method: 'POST', data: obj.array})
                    .then(resp => {
                        let data = resp.data;
                        commit('stop_mail_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('stop_mail_error')
                        reject(err.response.data)
                    })
            })
        },

        changeProxyAll({commit, state, getters, rootGetters}, mails) {
            return new Promise((resolve, reject) => {
                axios({url: `${process.env.VUE_APP_API_URL}/api-mail/change-proxies`, method: 'POST', data: mails})
                    .then(resp => {
                        let data = resp.data;
                        commit('change_proxies_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('change_proxies_error')
                        reject(err.response.data)
                    })
            })
        },


        getMails({commit, state, getters, rootGetters}) {
            return new Promise((resolve, reject) => {
                axios({url: `${process.env.VUE_APP_API_URL}/api-mail/get-mails`, method: 'GET'})
                    .then(resp => {
                        let data = resp.data;
                        commit('get_mails_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('get_mails_error')
                        reject(err.response.data)
                    })
            })
        },

        getMailsAll({commit, state, getters, rootGetters}) {
            return new Promise((resolve, reject) => {
                axios({url: `${process.env.VUE_APP_API_URL}/api-mail/get-mails-all`, method: 'GET'})
                    .then(resp => {
                        let data = resp.data;
                        commit('get_mails_all_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('get_mails_all_error')
                        reject(err.response.data)
                    })
            })
        },

        testMail({commit, state, getters, rootGetters}, {mail, old}) {
            return new Promise((resolve, reject) => {
                axios({
                    url: `${process.env.VUE_APP_API_URL}/api-mail/test-mail`,
                    method: 'POST',
                    data: mail,
                    params: {old}
                })
                    .then(resp => {
                        let data = resp.data;
                        commit('test_mail_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('test_mail_error')
                        reject(err.response.data)
                    })
            })
        },
        testMailsSelected({commit, state, getters, rootGetters}, {array, old}) {
            return new Promise((resolve, reject) => {
                axios({url: `${process.env.VUE_APP_API_URL}/api-mail/test-mails-selected`, method: 'POST', data: array, params: {old}})
                    .then(resp => {
                        let data = resp.data;
                        commit('test_mail_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('test_mail_error')
                        reject(err.response.data)
                    })
            })
        },
        assignProxies({commit, state, getters, rootGetters}, obj) {
            return new Promise((resolve, reject) => {
                axios({url: `${process.env.VUE_APP_API_URL}/api-mail/assign-proxies`, method: 'POST', data: obj.array})
                    .then(resp => {
                        let data = resp.data;
                        commit('assign_proxies_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('assign_proxies_error')
                        reject(err.response.data)
                    })
            })
        },
        setFlagged({commit, state, getters, rootGetters}, data) {
            return new Promise((resolve, reject) => {
                axios({url: `${process.env.VUE_APP_API_URL}/api-mail/set-flagged`, method: 'POST', data: data})
                    .then(resp => {
                        let data = resp.data;
                        commit('set_flagged_success');
                        resolve(data)
                    })
                    .catch(err => {
                        commit('set_flagged_error')
                        reject(err.response.data)
                    })
            })
        },
        setPause({commit, state, getters, rootGetters}, data) {
            return new Promise((resolve, reject) => {
                axios({url: `${process.env.VUE_APP_API_URL}/api-mail/set-pause`, method: 'POST', data: data})
                    .then(resp => {
                        let data = resp.data;
                        commit('set_pause_success');
                        resolve(data)
                    })
                    .catch(err => {
                        commit('set_pause_error')
                        reject(err.response.data)
                    })
            })
        },

    },
    getters: {}
}
