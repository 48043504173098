import store from "@/store";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
  },
  {
    path: "/test",
    name: "Test",
    component: () => import("@/views/Test"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login"),
  },
  {
    path: "/letters/:type",
    name: "Letters",
    component: () => import("@/views/Letters"),
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("@/views/Dashboard"),
  },
  {
    path: "/mails",
    name: "Mails",
    component: () => import("@/views/Mails"),
  },
  {
    path: "/proxies",
    name: "Proxies",
    component: () => import("@/views/Proxy"),
  },
  {
    path: "/keywords",
    name: "Keywords",
    component: () => import("@/views/Keyword"),
  },
  {
    path: "/exceptions",
    name: "Exception",
    component: () => import("@/views/Exception"),
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import("@/views/Settings"),
  },
  // {
  //     path: '/auth',
  //     name: 'Whitelist',
  //     component: () => import('@/views/Whitelist'),
  // },
  {
    path: "/edited",
    name: "Edited",
    component: () => import("@/views/Edited"),
  },
  {
    path: "/all-mails",
    name: "AllMails",
    component: () => import("@/views/AllMails"),
  },
];

// router.beforeEach((to, from) => {
//     // ...
//     // explicitly return false to cancel the navigation
//     // return true
// })

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");
  const role = store.getters["auth/role"];

  console.log("router token", token);
  console.log("router role", role);

  // console.log('role', store.getters['auth/role'])

  let adminPages = ["Mails", "Proxies", "Keywords", "Exception", "Settings"];

  // console.log('adminPages.includes(to.name)', to.name)
  if (to.name !== "Login" && !token) {
    next({ name: "Login" });
  } else {
    if (role != "worker") {
      next();
    } else {
      if (adminPages.includes(to.name)) {
        next({ name: "Login" });
      } else {
        next();
      }
    }
  }
});

export default router;
