import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default {
    namespaced: true,
    state: {},
    mutations: {
        download_success(state ) {
        },
        download_error(state) {
            console.log("download_error")
        },
    },
    actions: {
        download({commit, state, getters, rootGetters}, item) {
            return new Promise((resolve, reject) => {
                axios({
                    url: `${process.env.VUE_APP_API_URL}/api-attachment/download`,
                    method: 'POST',
                    data: item
                })
                    .then(resp => {

                        // console.log('resp', resp)
                        commit('download_success');
                        resolve(resp.data)
                    })
                    .catch(err => {
                        // console.log('err')
                        commit('download_error')
                        reject(err)
                    })
            })
        },
    }
}
