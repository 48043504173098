import Vue from 'vue'
import Vuex from 'vuex'
import profile from '@/store/profile'
import mail from '@/store/mail'
import ui from "@/store/ui";
import proxy from "@/store/proxy";
import keyword from "@/store/keyword";
import whitelist from "@/store/whitelist";
import letter from "@/store/letter";
import exception from "@/store/exception";
import database from "@/store/database";
import setting from "@/store/setting";
import attachment from "@/store/attachment";
import auth from "@/store/auth";


Vue.use(Vuex)

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        profile,
        mail,
        proxy,
        keyword,
        whitelist,
        letter,
        ui,
        exception,
        database,
        setting,
        attachment,
        auth
    }
})
