<template>
  <v-app>
    <v-app-bar v-if="$route.name != 'Login'" app color="primary" dark>
      <v-btn id="no-background-hover" icon to="/">
        <span class="group pa-2">
          <v-icon medium>mdi-home</v-icon>
        </span>
      </v-btn>

      <v-checkbox
        v-model="isDarkMode"
        class="ml-2"
        color="white"
        id="chooseTheme"
        off-icon="mdi-weather-night"
        on-icon="mdi-white-balance-sunny"
        @change="toggle_dark_mode"
      ></v-checkbox>

      <v-btn class="ml-2" text to="/letters/inbox">
        <span>Letters</span>
      </v-btn>

      <v-btn class="ml-2" text to="/edited">
        <span>Sent</span>
      </v-btn>

      <v-btn
        v-if="$store.state.auth.profile.role != 'worker'"
        class="ml-2"
        text
        to="/mails"
      >
        <span>Mails</span>
      </v-btn>

      <!--      <v-menu>-->
      <!--        <template v-slot:activator="{ on, attrs }">-->
      <!--          <v-btn text v-bind="attrs" v-on="on" class="ml-2">-->
      <!--            <span>Keywords</span>-->
      <!--          </v-btn>-->
      <!--        </template>-->

      <!--        <v-list>-->
      <!--          <v-list-item link to="/keywords/subject">-->
      <!--            <v-list-item-title> Subject</v-list-item-title>-->
      <!--          </v-list-item>-->
      <!--          <v-list-item link to="/keywords/email">-->
      <!--            <v-list-item-title> Email</v-list-item-title>-->
      <!--          </v-list-item>-->
      <!--          <v-list-item link to="/keywords/body">-->
      <!--            <v-list-item-title> Body</v-list-item-title>-->
      <!--          </v-list-item>-->
      <!--          <v-list-item link to="/keywords/attachment">-->
      <!--            <v-list-item-title> Attachment</v-list-item-title>-->
      <!--          </v-list-item>-->
      <!--        </v-list>-->
      <!--      </v-menu>-->

      <!--      <v-menu>-->
      <!--        <template v-slot:activator="{ on, attrs }">-->
      <!--          <v-btn text v-bind="attrs" v-on="on" class="ml-2">-->
      <!--            <span>Exceptions</span>-->
      <!--          </v-btn>-->
      <!--        </template>-->

      <!--        <v-list>-->
      <!--          <v-list-item link to="/exceptions/subject">-->
      <!--            <v-list-item-title> Subject</v-list-item-title>-->
      <!--          </v-list-item>-->
      <!--          <v-list-item link to="/exceptions/email">-->
      <!--            <v-list-item-title> Email</v-list-item-title>-->
      <!--          </v-list-item>-->
      <!--          <v-list-item link to="/exceptions/body">-->
      <!--            <v-list-item-title> Body</v-list-item-title>-->
      <!--          </v-list-item>-->
      <!--          <v-list-item link to="/exceptions/attachment">-->
      <!--            <v-list-item-title> Attachment</v-list-item-title>-->
      <!--          </v-list-item>-->
      <!--        </v-list>-->
      <!--      </v-menu>-->

      <!--      <v-btn text to="/dashboard/sentbox">-->
      <!--        <span>Sent</span>-->
      <!--      </v-btn>-->

      <!--      <v-btn text to="/edited">-->
      <!--        <span>Edited</span>-->
      <!--      </v-btn>-->

      <!--      <v-btn text to="/mails" class="ml-2">-->
      <!--        <span>Mails</span>-->
      <!--      </v-btn>-->

      <v-btn
        v-if="$store.state.auth.profile.role != 'worker'"
        class="ml-2"
        text
        to="/keywords"
      >
        <span>Keywords</span>
      </v-btn>

      <v-btn
        v-if="$store.state.auth.profile.role != 'worker'"
        class="ml-2"
        text
        to="/exceptions"
      >
        <span>Exceptions</span>
      </v-btn>

      <v-btn
        v-if="$store.state.auth.profile.role != 'worker'"
        class="ml-2"
        text
        to="/proxies"
      >
        <span>Proxies</span>
      </v-btn>

      <v-btn
        v-if="$store.state.auth.profile.role != 'worker'"
        class="ml-2"
        text
        to="/settings"
      >
        <span>Settings</span>
      </v-btn>
      <v-btn
        v-if="$store.state.auth.profile.role == 'main admin'"
        class="ml-2"
        text
        to="/all-mails"
      >
        <span>All Mails</span>
      </v-btn>

      <!--      <v-btn text to="/auth" class="ml-2">-->
      <!--        <span>Whitelist</span>-->
      <!--      </v-btn>-->

      <!--      <v-switch-->
      <!--          v-if="!loading"-->
      <!--          v-model="switch_turn"-->
      <!--          class="ml-auto"-->
      <!--          :label="`Panel is ${this.switch_turn == true ? 'on' : 'off'}`"-->
      <!--      ></v-switch>-->

      <!--      <v-progress-circular v-if="loading"-->
      <!--                           class="ml-auto"-->
      <!--                           color="white"-->
      <!--                           indeterminate-->
      <!--      ></v-progress-circular>-->

      <div class="ml-auto d-flex">
        <!-- <v-switch
          v-model="$vuetify.theme.dark"
          inset
          color="orange "
          label="Dark Mode"
        ></v-switch> -->

        <!-- <v-icon small class="mr-2" style="cursor: pointer"
          >fa-solid fa-moon</v-icon
        > -->

        <v-icon class="mr-2" small @click="refreshStats"> mdi-refresh </v-icon>
        <div>
          <b> Modified: </b> {{ stats.modified_today }}/{{ stats.modified_all }}
        </div>
        <div class="ml-1">
          <b>Intercepted:</b> {{ stats.intercepted_today }}/{{
            stats.intercepted_all
          }}
        </div>
      </div>

      <v-btn class="ml-2" color="error" @click="logout">
        <span>Exit</span>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view :key="$route.path" />
      <SnackBar></SnackBar>
    </v-main>
  </v-app>
</template>

<script>
import SnackBar from "@/components/SnackBar";
import axios from "axios";

export default {
  name: "App",
  loaded: false,

  components: {
    SnackBar,
  },
  data: () => ({
    loading: true,
    isDarkMode: false,
    adminPages: [
      "AllMails",
      "Mails",
      "Proxies",
      "Keywords",
      "Exception",
      "Settings",
      "Edited",
    ],
    stats: {
      intercepted_all: 0,
      intercepted_today: 0,
      modified_all: 0,
      modified_today: 0,
    },
    //
  }),
  computed: {
    switch_turn: {
      get() {
        return this.$store.state.setting.settings.is_turn;
      },
      set(value) {
        this.loading = true;

        console.log("value", value);
        this.$store
          .dispatch("setting/set_is_turn", value)
          .then((resp) => {
            console.log(resp);
            this.loading = false;
          })
          .catch((err) => {
            this.$store.dispatch("ui/showSnack", {
              text: "Error set settings",
            });
            this.loading = false;
          });
      },
    },
  },
  mounted() {
    this.loading = true;

    axios.defaults.headers.common = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    if (this.$store.state.auth.token) {
      this.$store
        .dispatch("auth/authorize", {})
        .then(() => {
          console.log("auth");

          this.$store
            .dispatch("setting/get_is_turn")
            .then((resp) => {
              console.log(resp);
              this.loading = false;
            })
            .catch((err) => {
              this.$store.dispatch("ui/showSnack", {
                text: "Error loading settings",
              });
              this.loading = false;
            });

          this.$store
            .dispatch("setting/getStats2")
            .then((resp) => {
              this.stats = resp.data;
            })
            .catch((err) => {});
        })
        .catch((err) => {
          console.log("error auth");
          this.$router.push("/login");
        });
    }

    const theme = localStorage.getItem("dark_theme");
    if (theme) {
      this.isDarkMode = theme === "true";
      this.$vuetify.theme.dark = this.isDarkMode;
    } else if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      this.isDarkMode = true;
      this.$vuetify.theme.dark = true;
      localStorage.setItem("dark_theme", this.isDarkMode.toString());
    }
  },
  methods: {
    toggle_dark_mode() {
      this.isDarkMode = !this.isDarkMode;

      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    },
    refreshStats() {
      this.$store
        .dispatch("setting/getStats2")
        .then((resp) => {
          this.stats = resp.data;
        })
        .catch((err) => {});
    },
    logout() {
      this.$store.commit("auth/logout");
      this.$router.push("/login");
    },
  },
};
</script>

<style>
.theme--light.v-data-table tbody tr {
  background-color: rgba(0, 0, 0, 0.03);
}

.theme--dark.v-data-table tbody tr {
  background-color: rgb(18, 18, 18);
}

.v-application--is-ltr .v-messages {
  display: none;
}

.v-input--selection-controls .v-input__slot,
.v-input--selection-controls .v-radio {
  margin: 0;
}


</style>
