import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default {
    namespaced: true,
    state: {},
    mutations: {
        add_profiles_success(state) {
            console.log("add_profiles_success")
        },
        add_profiles_error(state) {
            console.log("add_profiles_error")
        },

        edit_profiles_success(state) {
            console.log("add_profiles_success")
        },
        edit_profiles_error(state) {
            console.log("add_profiles_error")
        },

        delete_profiles_success(state) {
            console.log("delete_profiles_success")
        },
        delete_profiles_error(state) {
            console.log("delete_profiles_error")
        },
        select_profiles_success(state) {
            console.log("select_profiles_success")
        },
        select_profiles_error(state) {
            console.log("select_profiles_error")
        },
        get_profiles_success(state) {
            console.log("get_profiles_success")
        },
        get_profiles_error(state) {
            console.log("get_profiles_error")
        },
    },
    actions: {

        editProfile({commit, state, getters, rootGetters}, profile) {
            return new Promise((resolve, reject) => {
                axios({url: `${process.env.VUE_APP_API_URL}/api-profiles/edit-profile`, method: 'POST', data: profile})
                    .then(resp => {
                        let data = resp.data;
                        commit('edit_profiles_success');
                        resolve(data)
                    })
                    .catch(err => {
                        commit('edit_profiles_error')
                        reject(err.response.data)
                    })
            })
        },
        addProfile({commit, state, getters, rootGetters}, profile) {
            return new Promise((resolve, reject) => {
                axios({url: `${process.env.VUE_APP_API_URL}/api-profiles/add-profile`, method: 'POST', data: profile})
                    .then(resp => {
                        let data = resp.data;
                        commit('add_profiles_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('add_profiles_error')
                        reject(err.response.data)
                    })
            })
        },
        selectProfile({commit, state, getters, rootGetters}, profile) {
            return new Promise((resolve, reject) => {
                axios({url: `${process.env.VUE_APP_API_URL}/api-profiles/select-profile`, method: 'POST', data: profile})
                    .then(resp => {
                        let data = resp.data;
                        commit('select_profiles_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('select_profiles_error')
                        reject(err.response.data)
                    })
            })
        },
        deleteProfile({commit, state, getters, rootGetters}, profile) {
            return new Promise((resolve, reject) => {
                axios({url: `${process.env.VUE_APP_API_URL}/api-profiles/delete-profile`, method: 'POST', data: profile})
                    .then(resp => {
                        let data = resp.data;
                        commit('delete_profiles_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('delete_profiles_error')
                        reject(err.response.data)
                    })
            })
        },
        getProfiles({commit, state, getters, rootGetters}) {
            return new Promise((resolve, reject) => {
                axios({url: `${process.env.VUE_APP_API_URL}/api-profiles/get-profiles`, method: 'GET'})
                    .then(resp => {
                        let data = resp.data;
                        commit('get_profiles_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('get_profiles_error')
                        reject(err.response.data)
                    })
            })
        },

    },
    getters: {}
}
