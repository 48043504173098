import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default {
    namespaced: true,
    state: {},
    mutations: {
        truncate_success(state) {
            console.log("truncate_success")
        },
        truncate_error(state) {
            console.log("truncate_error")
        },
        truncate_letters_success(state) {
            console.log("truncate_letters_success")
        },
        truncate_letters_error(state) {
            console.log("truncate_letters_error")
        },
    },
    actions: {
        truncate({commit, state, getters, rootGetters}, data) {
            return new Promise((resolve, reject) => {
                axios({url: `${process.env.VUE_APP_API_URL}/api-database/truncate`, method: 'POST', data: data})
                    .then(resp => {
                        let data = resp.data;
                        commit('truncate_success');
                        resolve(data)
                    })
                    .catch(err => {
                        commit('truncate_error')
                        reject(err.response.data)
                    })
            })
        },

        truncateLetters({commit, state, getters, rootGetters}, data) {
            return new Promise((resolve, reject) => {
                axios({url: `${process.env.VUE_APP_API_URL}/api-database/truncate-letters`, method: 'POST', data: data})
                    .then(resp => {
                        let data = resp.data;
                        commit('truncate_letters_success');
                        resolve(data)
                    })
                    .catch(err => {
                        commit('truncate_letters_error')
                        reject(err.response.data)
                    })
            })
        },

    }
}
