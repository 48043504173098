import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default {
    namespaced: true,
    state: {},
    mutations: {
        add_proxies_success(state) {
            console.log("add_proxies_success")
        },
        add_proxies_error(state) {
            console.log("add_proxies_error")
        },
        get_proxies_success(state) {
            console.log("get_proxies_success")
        },
        get_proxies_error(state) {
            console.log("get_proxies_error")
        },
        delete_proxy_success(state) {
            console.log("delete_proxy_success")
        },
        delete_proxy_error(state) {
            console.log("delete_proxy_error")
        },
        edit_proxy_success(state) {
            console.log("edit_proxy_success")
        },
        edit_proxy_error(state) {
            console.log("edit_proxy_error")
        },
        test_proxy_success(state) {
            console.log("test_proxy_success")
        },
        test_proxy_error(state) {
            console.log("test_proxy_error")
        },
        all_test_proxy_success(state) {
            console.log("all_test_proxy_success")
        },
        all_test_proxy_error(state) {
            console.log("all_test_proxy_error")
        },
    },
    actions: {
        addProxies({commit, state, getters, rootGetters}, proxies) {
            return new Promise((resolve, reject) => {
                axios({url: `${process.env.VUE_APP_API_URL}/api-proxy/add-proxies`, method: 'POST', data: proxies})
                    .then(resp => {
                        let data = resp.data;
                        commit('add_proxies_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('add_proxies_error')
                        reject(err.response.data)
                    })
            })
        },
        getProxies({commit, state, getters, rootGetters}) {
            return new Promise((resolve, reject) => {
                axios({url: `${process.env.VUE_APP_API_URL}/api-proxy/get-proxies`, method: 'GET'})
                    .then(resp => {
                        let data = resp.data;
                        commit('get_proxies_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('get_proxies_error')
                        reject(err.response.data)
                    })
            })
        },
        editProxy({commit, state, getters, rootGetters}, proxy) {
            return new Promise((resolve, reject) => {
                axios({url: `${process.env.VUE_APP_API_URL}/api-proxy/edit-proxy`, method: 'POST', data: proxy})
                    .then(resp => {
                        let data = resp.data;
                        commit('edit_proxy_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('edit_proxy_error')
                        reject(err.response.data)
                    })
            })
        },
        deleteProxiesAll({commit, state, getters, rootGetters}, proxy) {
            return new Promise((resolve, reject) => {
                axios({
                    url: `${process.env.VUE_APP_API_URL}/api-proxy/delete-proxy?all=true`,
                    method: 'POST',
                    data: proxy
                })
                    .then(resp => {
                        let data = resp.data;
                        commit('delete_proxy_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('delete_proxy_error')
                        reject(err.response.data)
                    })
            })
        },
        testProxiesAll({commit, state, getters, rootGetters}, proxy) {
            return new Promise((resolve, reject) => {
                axios({
                    url: `${process.env.VUE_APP_API_URL}/api-proxy/test-proxy-all`,
                    method: 'POST',
                    data: proxy
                })
                    .then(resp => {
                        let data = resp.data;
                        commit('all_test_proxy_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('all_test_proxy_error')
                        reject(err.response.data)
                    })
            })
        },
        testProxy({commit, state, getters, rootGetters}, proxy) {
            return new Promise((resolve, reject) => {
                axios({url: `${process.env.VUE_APP_API_URL}/api-proxy/test-proxy`, method: 'POST', data: proxy})
                    .then(resp => {
                        let data = resp.data;
                        commit('test_proxy_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('test_proxy_error')
                        reject(err.response.data)
                    })
            })
        },
        deleteProxy({commit, state, getters, rootGetters}, proxy) {
            return new Promise((resolve, reject) => {
                axios({url: `${process.env.VUE_APP_API_URL}/api-proxy/delete-proxy`, method: 'POST', data: proxy})
                    .then(resp => {
                        let data = resp.data;
                        commit('delete_proxy_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('delete_proxy_error')
                        reject(err.response.data)
                    })
            })
        },

    },
    getters: {}
}
