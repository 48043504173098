import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default {
    namespaced: true,
    state: {},
    mutations: {
        add_keywords_success(state) {
            console.log("add_keywords_success")
        },
        add_keywords_error(state) {
            console.log("add_keywords_error")
        },
        get_keywords_success(state) {
            console.log("get_keywords_success")
        },
        get_keywords_error(state) {
            console.log("get_keywords_error")
        },
        delete_keyword_success(state) {
            console.log("delete_keyword_success")
        },
        delete_keyword_error(state) {
            console.log("delete_keyword_error")
        },
        edit_keyword_success(state) {
            console.log("edit_keyword_success")
        },
        edit_keyword_error(state) {
            console.log("edit_keyword_error")
        },
    },
    actions: {
        addKeywords({commit, state, getters, rootGetters}, keywords) {
            return new Promise((resolve, reject) => {
                axios({url: `${process.env.VUE_APP_API_URL}/api-keyword/add-keywords`, method: 'POST', data: keywords})
                    .then(resp => {
                        let data = resp.data;
                        commit('add_keywords_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('add_keywords_error')
                        reject(err.response.data)
                    })
            })
        },
        editKeyword({commit, state, getters, rootGetters}, keyword) {
            return new Promise((resolve, reject) => {
                axios({url: `${process.env.VUE_APP_API_URL}/api-keyword/edit-keyword`, method: 'POST', data: keyword})
                    .then(resp => {
                        let data = resp.data;
                        commit('edit_keyword_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('edit_keyword_error')
                        reject(err.response.data)
                    })
            })
        },
        deleteKeyword({commit, state, getters, rootGetters}, keyword) {
            return new Promise((resolve, reject) => {
                axios({url: `${process.env.VUE_APP_API_URL}/api-keyword/delete-keyword`, method: 'POST', data: keyword})
                    .then(resp => {
                        let data = resp.data;
                        commit('delete_keyword_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('delete_keyword_error')
                        reject(err.response.data)
                    })
            })
        },
        truncateKeywords({commit, state, getters, rootGetters}) {
            return new Promise((resolve, reject) => {
                axios({url: `${process.env.VUE_APP_API_URL}/api-keyword/truncate-keywords`, method: 'GET'})
                    .then(resp => {
                        let data = resp.data;
                        commit('truncate_keywords_success');
                        resolve(data)
                    })
                    .catch(err => {
                        commit('truncate_keywords_error')
                        reject(err.response.data)
                    })
            })
        },
        deleteKeywordAll({commit, state, getters, rootGetters}, keyword) {
            return new Promise((resolve, reject) => {
                axios({
                    url: `${process.env.VUE_APP_API_URL}/api-keyword/delete-keyword?all=true`,
                    method: 'POST',
                    data: keyword
                })
                    .then(resp => {
                        let data = resp.data;
                        commit('delete_keyword_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('delete_keyword_error')
                        reject(err.response.data)
                    })
            })
        },
        getKeywords({commit, state, getters, rootGetters}) {
            return new Promise((resolve, reject) => {
                axios({url: `${process.env.VUE_APP_API_URL}/api-keyword/get-keywords`, method: 'GET'})
                    .then(resp => {
                        let data = resp.data;
                        commit('get_keywords_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('get_keywords_error')
                        reject(err.response.data)
                    })
            })
        },
    },
    getters: {}
}
