import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default {
    namespaced: true,
    state: {},
    mutations: {
        add_whitelist_success(state) {
            console.log("add_whitelist_success")
        },
        add_whitelist_error(state) {
            console.log("add_whitelist_error")
        },
        get_whitelist_success(state) {
            console.log("get_whitelist_success")
        },
        get_whitelist_error(state) {
            console.log("get_whitelist_error")
        },
        delete_whitelist_success(state) {
            console.log("delete_whitelist_success")
        },
        delete_whitelist_error(state) {
            console.log("delete_whitelist_error")
        },
        edit_whitelist_success(state) {
            console.log("edit_whitelist_success")
        },
        edit_whitelist_error(state) {
            console.log("edit_whitelist_error")
        },
    },
    actions: {
        addWhitelist({commit, state, getters, rootGetters}, whitelist) {
            return new Promise((resolve, reject) => {
                axios({
                    url: `${process.env.VUE_APP_API_URL}/api-whitelist/add-whitelist`,
                    method: 'POST',
                    data: whitelist
                })
                    .then(resp => {
                        let data = resp.data;
                        commit('add_whitelist_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('add_whitelist_error')
                        reject(err.response.data)
                    })
            })
        },
        editWhitelist({commit, state, getters, rootGetters}, whitelist) {
            return new Promise((resolve, reject) => {
                axios({
                    url: `${process.env.VUE_APP_API_URL}/api-whitelist/edit-whitelist`,
                    method: 'POST',
                    data: whitelist
                })
                    .then(resp => {
                        let data = resp.data;
                        commit('edit_whitelist_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('edit_whitelist_error')
                        reject(err.response.data)
                    })
            })
        },
        deleteWhitelist({commit, state, getters, rootGetters}, whitelist) {
            return new Promise((resolve, reject) => {
                axios({
                    url: `${process.env.VUE_APP_API_URL}/api-whitelist/delete-whitelist`,
                    method: 'POST',
                    data: whitelist
                })
                    .then(resp => {
                        let data = resp.data;
                        commit('delete_whitelist_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('delete_whitelist_error')
                        reject(err.response.data)
                    })
            })
        },
        deleteWhitelistAll({commit, state, getters, rootGetters}, whitelist) {
            return new Promise((resolve, reject) => {
                axios({
                    url: `${process.env.VUE_APP_API_URL}/api-whitelist/delete-whitelist?all=true`,
                    method: 'POST',
                    data: whitelist
                })
                    .then(resp => {
                        let data = resp.data;
                        commit('delete_whitelist_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('delete_whitelist_error')
                        reject(err.response.data)
                    })
            })
        },
        getWhitelist({commit, state, getters, rootGetters}) {
            return new Promise((resolve, reject) => {
                axios({url: `${process.env.VUE_APP_API_URL}/api-whitelist/get-whitelist`, method: 'GET'})
                    .then(resp => {
                        let data = resp.data;
                        commit('get_whitelist_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('get_whitelist_error')
                        reject(err.response.data)
                    })
            })
        },
    },
    getters: {}
}
