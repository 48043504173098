import CKEditor from "@ckeditor/ckeditor5-vue2";
import "typeface-roboto/index.css";
import Vue from "vue";
import Dayjs from "vue-dayjs";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

Vue.use(Dayjs, {});
Vue.use(CKEditor);

import VueExcelXlsx from "vue-excel-xlsx";
Vue.use(VueExcelXlsx);

new Vue({
  router,
  store,
  vuetify,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
