import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default {
    namespaced: true,
    state: {
        token: localStorage.getItem('token') || null,
        profile: {},
        role: localStorage.getItem('role') || '',
    },
    mutations: {
        authorize_success(state, {data}) {
            state.profile = data.data
            state.role = data.data.role
            console.log("authorize_success")
        },
        authorize_error(state) {
            console.log("authorize_error")
        },
        login_success(state, {data}) {
            state.profile = data.profile
            state.role = data.profile.role
            console.log("authorize_success")
        },
        login_error(state) {
            console.log('login_error')
        },
        logout(state) {
            state.profile = null
            state.token = null
            localStorage.removeItem('token')
            console.log("logout_success")
        },
    },
    actions: {
        authorize({commit, state, getters, rootGetters}) {
            return new Promise((resolve, reject) => {
                axios({
                    url: `${process.env.VUE_APP_API_URL}/api-profiles/authorize`,
                    method: 'GET',
                })
                    .then(resp => {
                        let data = resp.data;
                        commit('authorize_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        localStorage.removeItem('token')
                        commit('authorize_error')
                        reject(err.response.data)
                    })
            })
        },
        login({commit, state, getters, rootGetters}, user) {
            return new Promise((resolve, reject) => {
                axios({url: `${process.env.VUE_APP_API_URL}/api-profiles/login`, method: 'POST', data: user})
                    .then(resp => {
                        let data = resp.data;

                        console.log('data', data.token.token)
                        localStorage.setItem('token', data.token.token)
                        console.log('role34', data.profile.role)
                        localStorage.setItem('role', data.profile.role)
                        axios.defaults.headers.common = {'Authorization': `Bearer ${localStorage.getItem('token')}`}
                        commit('login_success', {data});
                        resolve()
                    })
                    .catch(err => {
                        console.log(err)
                        localStorage.removeItem('token')
                        commit('login_error')
                        reject(err)
                    })
            })
        },
    },
    getters: {
        role(state) {
            return state.role
        }
    }
}
