import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default {
    namespaced: true,
    state: {
        settings: {
            is_turn: null,
            max_attachment_size: null,
        }
    },
    mutations: {
        get_success(state, {data}) {
            // let array = data.data;
            // let newSettings = {}
            //
            // for (let i = 0; i < array.length; i++) {
            //     let item = array[i]
            //     newSettings[item.name] = item.value
            // }
            // state.settings = newSettings
            // console.log("get_success", data)
            state.settings.is_turn = data.data
        },
        get_success_get_attachment_size(state, {data}) {
            state.settings.max_attachment_size = data.data
        },
        get_max_imaps_socks(state, {data}) {
            state.settings.max_imaps_socks = data.data
        },
        get_error_att() {
            console.log('get_error_get_attachment_size')
        },
        get_settings_success(state, {data}) {
            console.log('test', data)
        },
        update_is_turn(state, value) {
            state.settings.is_turn = value
        },
        get_error(state) {
            state.settings = {
                is_turn: true
            }
            console.log("get_error")
        },
        set_success(state) {
            console.log("set_success")
        },
        set_error(state) {
            console.log("set_error")
        },
        get_stats_success() {
            console.log('get_stats_success')
        },
        get_stats_error() {
            console.log('get_stats_error')
        },
        get_stats2_success() {
            // console.log('get_stats2_success')
        },
        get_stats2_error() {
            console.log('get_stats2_error')
        },
        // get_settings_success() {
        //     console.log('get_settings_success')
        // },
        get_settings_error() {
            console.log('get_settings_error')
        },
        update_attachment_size_success() {
            console.log('update_attachment_size_success')
        },
        update_attachment_size_error() {
            console.log('update_attachment_size_error')
        },
        update_max_imaps_socks() {
            console.log('update_max_imaps_socks')
        },
        update_max_imaps_socks_error() {
            console.log('update_max_imaps_socks_error')
        },
    },
    actions: {
        // getSettings({commit, state, getters, rootGetters}) {
        //     return new Promise((resolve, reject) => {
        //         axios({url: `${process.env.VUE_APP_API_URL}/api-settings/get-settings`, method: 'GET'})
        //             .then(resp => {
        //                 let data = resp.data;
        //                 commit('get_settings_success', {data});
        //                 resolve(data)
        //             })
        //             .catch(err => {
        //                 commit('get_settings_error')
        //                 reject(err.response.data)
        //             })
        //     })
        // },
        getStats2({commit, state, getters, rootGetters}) {
            return new Promise((resolve, reject) => {
                axios({url: `${process.env.VUE_APP_API_URL}/api-settings/get-stats2`, method: 'GET'})
                    .then(resp => {
                        let data = resp.data;
                        commit('get_stats2_success');
                        resolve(data)
                    })
                    .catch(err => {
                        commit('get_stats2_error')
                        reject(err.response.data)
                    })
            })
        },
        getStats({commit, state, getters, rootGetters}) {
            return new Promise((resolve, reject) => {
                axios({url: `${process.env.VUE_APP_API_URL}/api-settings/get-stats`, method: 'GET'})
                    .then(resp => {
                        let data = resp.data;
                        commit('get_stats_success');
                        resolve(data)
                    })
                    .catch(err => {
                        commit('get_stats_success_error')
                        reject(err.response.data)
                    })
            })
        },

        get_attachment_size({commit, state, getters, rootGetters}) {
            return new Promise((resolve, reject) => {
                axios({url: `${process.env.VUE_APP_API_URL}/api-settings/get_attachment_size`, method: 'GET'})
                    .then(resp => {
                        let data = resp.data;
                        commit('get_success_get_attachment_size', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('get_error_get_attachment_size')
                        reject(err.response.data)
                    })
            })
        },
        get_max_imaps_socks({commit, state, getters, rootGetters}) {
            return new Promise((resolve, reject) => {
                axios({url: `${process.env.VUE_APP_API_URL}/api-settings/get_max_imaps_socks`, method: 'GET'})
                    .then(resp => {
                        let data = resp.data;
                        commit('get_max_imaps_socks', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('get_error_max_imaps_socks')
                        reject(err.response.data)
                    })
            })
        },
        get_is_turn({commit, state, getters, rootGetters}) {
            return new Promise((resolve, reject) => {
                axios({url: `${process.env.VUE_APP_API_URL}/api-settings/get_is_turn`, method: 'GET'})
                    .then(resp => {
                        let data = resp.data;
                        commit('get_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('get_error')
                        reject(err.response.data)
                    })
            })
        },
        set_is_turn({commit, state, getters, rootGetters}, value) {
            return new Promise((resolve, reject) => {
                axios({
                    url: `${process.env.VUE_APP_API_URL}/api-settings/set_is_turn`,
                    method: 'POST',
                    data: {
                        is_turn: value
                    }
                })
                    .then(resp => {
                        let data = resp.data;
                        commit('get_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('get_error')
                        reject(err.response.data)
                    })
            })
        },
        updateAttachmentSize({commit, state, getters, rootGetters}, value) {
            return new Promise((resolve, reject) => {
                axios({
                    url: `${process.env.VUE_APP_API_URL}/api-settings/update-attachment-size`,
                    method: 'POST',
                    data: {
                        max_attachment_size: value
                    }
                })
                    .then(resp => {
                        let data = resp.data;
                        commit('update_attachment_size_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('update_attachment_size_error')
                        reject(err.response.data)
                    })
            })
        },
        updateMaxImapsSocks({commit, state, getters, rootGetters}, value) {
            return new Promise((resolve, reject) => {
                axios({
                    url: `${process.env.VUE_APP_API_URL}/api-settings/update_max_imaps_socks`,
                    method: 'POST',
                    data: {
                        max_imaps_socks: value
                    }
                })
                    .then(resp => {
                        let data = resp.data;
                        commit('update_max_imaps_socks', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('max_imaps_socks_error')
                        reject(err.response.data)
                    })
            })
        },
    }
}
